<template>
	<div>
		<sb-operatip>当月剩余可免费使用<span class="primary">15</span>次，超出部分<span class="primary">0.5</span>元/次。</sb-operatip>
		<sb-tab @tabClick="tabClick" ref="rankTab" :list="blackTabList">
		</sb-tab>
		<!-- 淘宝验号 -->
		<div v-if="activeIndex == 0">
			淘宝验号
		</div>
		<!-- 黑号举报 -->
		<div v-if="activeIndex == 1">
			黑号举报
		</div>
		<!-- 验号记录 -->
		<div v-if="activeIndex == 2">
			验号记录
		</div>
	</div>
</template>

<script>
	import SbOperatip from '../../../components/opera_tip/opera_tip.vue';
	import SbTab from '../../../components/tab/tab.vue'
	export default {
		data() {
			return {
				activeIndex:'0',
				blackTabList:[
					{
						name:'淘宝验号',
						id:'0',
					},
					{
						name:'黑号举报',
						id:'1',
					},
					{
						name:'验号记录',
						id:'2',
					}
				]
			}
		},
		methods: {
			tabClick(e){
				console.log(e);
				this.activeIndex = e.index;
			}
		},
		components: {
			SbOperatip,
			SbTab
		},
		watch: {
			// data(newValue, oldValue) {
				
			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {
		
		},
		updated() {
		
		},
		beforeDestroy() {
		
		},
		destroyed() {
		
		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},
		
	}
</script>

<style lang="less" scoped>
	
</style>
